import * as React from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import Contact from "../components/contact"

// markup
const AboutPage = () => {
    return (
        <main>
            <Header />
            <Contact />
            <Footer />
        </main>
    )
}

export default AboutPage
